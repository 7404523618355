<template>
  <header></header>
    <div id="AuthPage">
      <!-- <div class="header">
        <NuxtLink to="/" class="logo-link">
          <img width="170" :src="MainLogo" alt="Main Logo">
        </NuxtLink>
      </div> -->
      <Header></Header>
  
      <div class="container">
        <div class="left-side">
          <div class="hero-photos">
            <img class="hero-photo" :src=SideLandingPhoto alt="Woman Photo">
            <img class="hero-photo" :src=SideLandingPhoto2 alt="Man Photo">
          </div>
          <h1>Find Your Best Photos and Find Your Perfect Match</h1>
          <p>Join our community to discover your best photos and elevate your social media presence.</p>
        </div>
        <div class="right-side-auth">
          <div class="login-form">
            <div class="title">Start Your Journey</div>
            <p class="subtitle">Sign in to continue to your account</p>
  
            <button @click="login('google')" class="google-login-button">
              Login with Google
            </button>
  
            <p class="signup-link"  @click="login('google')">Don't have an account? <NuxtLink>Sign up</NuxtLink></p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, watchEffect } from 'vue'
  import { useRouter } from 'vue-router'
  import { MainLogo, SideLandingPhoto, SideLandingPhoto2 } from '~/models/types'
  import Header from '~/components/main/Header.vue';
  
  const client = useSupabaseClient()
  const user = useSupabaseUser()
  const router = useRouter()
  
  watchEffect(() => {
    if (user.value) {
      router.push('/')
    }
  })
  
  const login = async (provider) => {
    const { error } = await client.auth.signInWithOAuth({
      provider: provider,
      redirectTo: window.location.origin
    })
    if (error) console.error('Error logging in:', error)
  }
  </script>
  
  <style scoped>
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: 'Arial', sans-serif;
  }
  
  #AuthPage {
    width: 100%;
    height: 100vh;
    background: #f9f9f9;
    display: flex;
    flex-direction: column;
  }
  
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .logo-link {
    min-width: 170px;
  }
  
  .container {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1200px;
    height: 100%;
    padding: 2rem;
  }
  
  @media (min-width: 768px) {
    .container {
      flex-direction: row;
    }
  }
  
  .left-side {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem;
  }
  
  .hero-photos {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .hero-photo {
    width: 100%;
    max-width: 200px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .left-side h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    font-weight: bold;
    color: #333;
  }
  
  .left-side p {
    font-size: 1.2rem;
    max-width: 400px;
    color: #666;
  }
  
  .right-side-auth {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login-form {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
    border: 1px solid #e5e7eb;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: white;
  }
  
  .title {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #333;
  }
  
  .subtitle {
    font-size: 1rem;
    margin-bottom: 2rem;
    color: #888;
  }
  
  .google-login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    padding: 0.75rem;
    width: 100%;
    border: 1px solid #d1d5db;
    background: white;
    border-radius: 9999px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
  }
  
  .google-login-button:hover {
    background: #f3f4f6;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .signup-link {
    font-size: 0.9rem;
    color: #666;
    margin-top: 1rem;
  }
  
  .signup-link a {
    color: #0066ff;
    text-decoration: none;
  }
  
  .signup-link a:hover {
    text-decoration: underline;
  }
  </style>
  